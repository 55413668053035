@import '../../../base/assets/scss/_base/config';

$hover-bg-color: rgba(0, 0, 0, 0.05);
$border-color: rgba(0, 0, 0, 0.40);

.m-AolHeader.m-AolHeader--rebrand {
  #header-title-bar:not(.header-24_7-help) {
    margin-right: -14px;
    .m-mail--new {
      margin-left: 10px;
    }
    .m-profile__btn {
      &--text {
        max-width: 100px;
      }
    }
  }

  #header-title-bar{
    .m-profile__btn {
      &--text {
        max-width: 70px;
      }
    }
  }
  .m-AolHeader__top-wrap {
    // Overall BG color
    background-color: $submarine-yellow;

    .logo{
      margin-right: 10px;
    }
    // Search Bar
    #header-form {
      background-color: $submarine-yellow;

      #header-form-search-input {
        border-color: var(--header-search-border-color);
        background-color: var(--header-search-bg-color);

        &::placeholder {
          color: $black;
        }
      }

      #header-form-search-button {
        transition: none;
        border-color: var(--header-search-border-color);
        background-color: var(--header-search-bg-color);

        svg {
          fill: $black;
        }
      }

      &.form-interacted:focus-within .header-search-form,
      .header-search-form:hover,
      .header-search-form.wafer-autocomplete-active {
        #header-form-search-input,
        #header-form-search-button,
        #header-form-search-input-list ul {
          background-color: $white;
        }
      }
    }

    // Help CTA (US)
    .m-cs__btn {
      color: $black;
      background-color: $submarine-yellow;
      border: 1px solid transparent;
      padding: 12px 14px;

      svg {
        fill: $black;
      }

      &:hover {
        color: $black;
        background-color: $hover-bg-color;
        border-color: $border-color;

        svg {
          fill: $black;
        }
      }
    }

    // Help CTA (INTL)
    .user-navigation__item {
      .user-navigation__btn {
        border: 1px solid transparent;
        color: $black;
        background-color: $submarine-yellow;
        padding: 12px 14px;

        &:hover {
          border-color: $border-color;
          color: $black;
          background-color: $hover-bg-color;
        }
      }
    }

    // Mail
    .m-mail--new {

      .m-mail-count.m-mail-count--usernav {
        background-color: $black;
        border-color: black;
        font-size: 11px;
        line-height: 1;
        padding: 3px;
        right: 0 !important;
        min-width: 20px;
      }

      .m-mail__lbl {
        color: $black;
      }

      svg {
        fill: $black;
        margin-left: 0 !important;
      }

      .m-mail__button {
        margin-right: 0 !important;
        border: 1px solid transparent;
        padding: 4px 7px;

        &:hover {
          .m-mail__lbl {
            color: $black;
          }

          border-color: $border-color;
          background-color: $hover-bg-color;

          svg {
            fill: $black;
          }
        }
      }
    }

    //Profile (Signed out)
    .m-profile__signin {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      padding: 0 12px;
      margin-left: 0;
      color: $black;
      border-color: $border-color;

      .running-man {
        position: relative;
        height: 38px;
        width: 30px;
        overflow: hidden;
        margin-right: 6px;

        svg {
          position: absolute;
          bottom: -6px;
          left: 0;
        }
      }

      &:hover {
        border-color: $border-color;
        background-color: $hover-bg-color;
        color: black;

        .running-man svg {
          fill: $black;
        }
      }
    }

    //Profile (Signed in)
    .m-profile__btn {
      border: 1px solid transparent;
      background-color: transparent;
      padding: 10px 24px;

      &--text {
        line-height: 1;
      }

      &:hover {
        border-color: $border-color;
        background-color: $hover-bg-color;
        color: $black;

        svg {
          fill: $black;
        }
      }
    }

    // Welcomescreen Refresh Btn
    .m-wsrefresh .wsrefresh {
      color: $black;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)) {
  .m-AolHeader.m-AolHeader--rebrand {
    .logo{
      margin-right: 0;
    }
    #header-title-bar:not(.header-24_7-help) {
      margin-right: 0px;
    }
    .m-AolHeader__top-wrap {
      // Mail
      .m-mail--new {
        .m-mail-count.m-mail-count--usernav {
          right: 3px !important;
        }
        .m-mail__button {
          padding: 4px 12px;
        }
        svg {
          margin-left: 8px !important;
        }
      }
    }
  }

}
