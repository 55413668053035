
.m-AolHeader.m-AolHeader--grid{

  .m-AolHeader__grid-wrapper{
    height: 100%;

    .m-AolHeader--innerContainer{
      max-width: none;
      .title-bar{
        margin: 0;
      }
    }
  }

  .top-wrap{
    .m-AolHeader__grid-wrapper{
      .m-AolHeader--innerContainer{
        padding: 0;
      }

      .title-bar{
        padding-left: 0;
        padding-right: 0;
        &__burger{
          padding-left: 0;
        }

        .m-mail__button{
          margin-right: -14px;
          .m-mail-count{
            right: -12px;
          }
        }
      }

      #header-form{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .m-AolHeader__lnav--innerContainer{
    height: 0;
  }

  .left-wrap{
    position: relative;
    left: -16px;

    #header-lnav{
      .navrail{
        display: none;
        .navrail__divider{
          display: none;
          padding: 16px;
        }
        a{
          display: none;
          .navicon{
            margin-left: 0;
          }
          .text-label,
          .navicon__img{
            display: none;
          }
        }
      }
    }
  }


  @media screen and (min-width: map-get($breakpoints, s)) {

    .m-AolHeader__spacer--header{
      height: 120px !important;
    }
    .top-wrap{
      height: 84px !important;
      .m-AolHeader {
        &--shadow {
          top: 84px;
        }

        &--innerContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
        }
      }
      .title-bar {
        flex:  0 0;
        padding: 0;
        &__burger, .m-profile__signin, .m-mail--new, .m-mail--new .m-mail__button {
          display: none;
        }
      }
      #header-form{
        flex: 1 1 100%;
        position: static;
        max-width: none;
        padding-left: 20px;
      }
      #user-navigation{
        flex: 1 1 40%;
        display: flex;
        position: static;
        .user-navigation{
          display: flex;
          .m-mail__lbl{
            display: block;
          }
        }
      }
    }
    .left-wrap{
      width: 60px;
      padding-top: 25px;
      #header-lnav{
        .navrail{
          display: flex;
          >li{
            margin: 0;

            > a .navicon.active svg{
              fill: $picasso
            }
          }
          .navrail__divider{
            display: block;
          }
          a{
            display: flex;
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, l)) {
    .left-wrap{
      width: 190px;
      #header-lnav{
        .navrail{
          a{
            .text-label,
            .navicon__img{
              display: flex;
            }
          }
        }
      }
    }

    &.m-AolHeader--smallnav{
      .left-wrap{
        width: 60px;
        #header-lnav{
          .navrail{
            a{
              .text-label,
              .text-label__badge,
              .navicon__img,
              .navrail-submenu{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
