@import '../../../base/assets/scss/_base/config';

.m-flyout{
  position: relative;
  z-index: 10;

  &.m-flyout--high-z{
    z-index: 1000;
    .m-flyout--open{
      z-index: 2000;
    }
  }

  &--open{
    z-index: 20;
    &.m-flyout--overlay{
      &:after{
        z-index: -1;
        content: '';
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        background-color: black;
        opacity: 0.25;
      }
    }
    .m-flyout{
      &__container{
        display: block;
        a, button{
          display: block;
        }
      }
    }
  }

  &.m-flyout--transition{
    // Closed (Default)
    .m-flyout__container{
      display: block;
      visibility: hidden;
      transition: transform $speed-slowest $ease, visibility $speed-slowest $ease $speed-slowest;

      &--right-fixed{
        transform: translateX(100%);
      }
      &--left-fixed{
        transform: translateX(-100%);
      }

      a, button{
        display: block;
        visibility: hidden;
        transition: visibility $speed-slowest $ease $speed-slowest;
      }
    }

    // Opened
    &.m-flyout--open{
      .m-flyout__container{
        transition: visibility 0s, transform $speed-slowest $ease;
        visibility: visible;
        transform: translateX(0);

        a, button{
          visibility: visible;
          transition: visibility 0s, transform $speed-slowest $ease;
        }
      }
    }

  }

  &__container{
    margin: 8px;
    display: none;
    background: $white;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    position: absolute;

    // All a, button tags must be set to display none so rapid does not fire for them when flyout is close
    a, button{
      display: none;
    }
    &--top{
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &--bottom{
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &--right{
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
    }

    &--left{
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
    }

    &--right-fixed, &--left-fixed{
      overflow-y: scroll;
      scrollbar-width: none; // Firefox Hide scrollbar
      -ms-overflow-style: none; // IE Hide scrollbar
      &::-webkit-scrollbar { // Webkit Hide scrollbar
        display: none;
      }
      position: fixed;
      top: 0;
      bottom: 0;
      margin: 0;
      border-radius: 0;
    }

    &--right-fixed{
      right: 0;
      left: 65px;
    }

    &--left-fixed{
      left: 0;
      right: 65px;
    }
  }
}
