@import '../../../base/assets/scss/_base/config';

@mixin lnav_v3 {
  .navrail__divider {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  li {

    a,
    .navrail_subitem {
      margin-top: 4px !important;
      margin-bottom: 4px !important;
    }

    .parent_label {
      margin-top: 2px !important;
    }

    &.subsnl-link a,
    .navrail_subitem_back {
      margin-top: 2px !important;
      margin-bottom: 2px !important;
    }
  }
}

@mixin subnav {
  li {
    .navicon {
      display: none !important;

      &_back {
        display: flex !important;
        padding-right: 16px !important;
      }
    }

    .text-label {
      display: flex !important;
      padding-left: 0px !important;

      &__badge {
        margin-left: 0px !important;
        display: inline-block !important;
      }
    }

    &.item--badge a {
      .navicon:after {
        display: none !important;
      }

      .text-label {
        padding-left: 10px !important;

        &_with_badge {
          order: 2 !important;
        }
      }
    }
  }
}

.m-ahlb {
  display: none;
  z-index: 999;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.m-AolHeader--innerContainer {
  #left-wrap_id {
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation: fade-in $speed ease-in-out forwards;

    &.hide {
      display: none;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @media screen and (min-width: map-get($breakpoints, s)) {
      &.lnav_layer {
        padding-top: 36px;
      }

      .navrail__divider {
        padding-top: 10px !important;
      }

      li {

        a,
        .navrail_subitem {
          padding-top: 14px !important;
          padding-bottom: 14px !important;
        }

        &.subsnl-link a,
        .navrail_subitem_back {
          padding-top: 14px !important;
          padding-bottom: 14px !important;
        }
      }
    }

    @media screen and (min-width: map-get($breakpoints, l)) {
      &.lnav_layer {
        padding-top: 36px;
      }
    }

    .navrail {
      &.hide {
        display: none;
      }

      &-container {
        &.hide {
          display: none;
        }

        &.translate_left {
          transition: transform $speed linear;
          transform: translateX(-300px);
        }

        &.translate_right {
          animation: translate-right $speed linear forwards;
        }

        @keyframes translate-right {
          0% {
            transform: translateX(-300px);
          }

          100% {
            transform: translateX(0px);
          }
        }
      }

      @media screen and (min-width: map-get($breakpoints, l)) {
        &.lnav_layer {
          @include lnav_v3;
        }

        &.subnav {
          @include subnav;
        }
      }

      li.lnav-parent-label {
        @media screen and (min-width: map-get($breakpoints, s)) and (max-width: map-get($breakpoints, l)) {
          display: none !important;
        }
      }
    }
  }
}

.m-header-flyouts {
  #lnav-mweb-flyout {

    &.transition_left {
      transition: transform $speed linear;
      transform: translateX(-200px);
    }

    &.transition_right {
      transition: transform $speed linear;
      transform: translateX(0px);
    }

    .navrail {
      &.hide {
        display: none;
      }

      &.subnav {
        @include subnav;

        li {
          a {
            margin-left: 20px;
          }

          .navrail_subitem_back {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)) {
  .m-ahlb {
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation: fade-in $speed ease-in-out forwards;

    &.hide {
      display: none;
    }

    &.fadeout {
      opacity: 1;
      animation: fade-out $speed ease-in-out forwards;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fade-out {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    &-top {
      margin-top: 0px;
    }

    &-bottom {
      display: flex;
    }

    &-left {
      width: 240px;
      background-color: $white;
      overflow-y: scroll;
      scrollbar-width: none; // Firefox Hide scrollbar
      -ms-overflow-style: none; // IE Hide scrollbar

      &::-webkit-scrollbar {
        // Webkit Hide scrollbar
        display: none;
      }

      &-top {
        background: linear-gradient(180deg, rgba(0, 0, 0, .05), transparent);
        height: 32px;
      }

      &-bottom {
        padding-top: 4px;

        &-space {
          z-index: 9999;
          display: flex;
          flex-grow: 1;
          background: #fff;
        }

        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
      }

      .navrail {
        &.hide {
          display: none;
        }

        width: 200px !important;

        &-container {
          &.hide {
            display: none;
          }

          &.translate_left {
            animation: translate-left $speed linear forwards;
          }

          @keyframes translate-left {
            0% {
              transform: translateX(0px);
            }

            100% {
              transform: translateX(-200px);
            }
          }

          &.translate_right {
            animation: translate-right $speed linear forwards;
          }

          @keyframes translate-right {
            0% {
              transform: translateX(-150px);
            }

            100% {
              transform: translateX(0px);
            }
          }
        }

        @include lnav_v3;

        li {
          &.lnav-parent-label {
            display: flex !important;
          }

          .text-label {
            display: flex !important;

            &__badge {
              display: inline-block !important;
            }
          }

          &.item--badge a {
            .navicon {
              display: none !important;

              &:after {
                display: none !important;
              }
            }

            .text-label {
              padding-left: 10px !important;

              &_with_badge {
                order: 2 !important;
              }
            }
          }
        }

        .navrail__divider {
          margin-top: 2px !important;
          margin-bottom: 2px !important;
        }

        &.subnav {
          @include subnav;
        }
      }
    }

    &-right {
      display: flex;
      flex-grow: 1;
      background-color: rgba(18, 22, 25, 0.75);

      &-left {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 2.92%, rgba(0, 0, 0, 0.40) 97.84%);
        width: 60px;
      }

      &__closeBtn {
        width: 32px;
        margin-top: 20px;
        margin-left: -50px;
        z-index: 9999999;
        cursor: pointer;

        svg {
          fill: $white;
        }
      }

    }

    &-left,
    &-right {
      height: 100vh;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, m)) {
  .m-ahlb {
    &-left {
      width: 240px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, l)) {
  .m-ahlb {
    &-left {
      width: 280px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  .m-ahlb {
    &-left {
      width: calc(50% - 840px + 290px);
    }
  }
}